import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useBidContext } from '../../../../providers/BidProvider/BidProvider';
import { MButton } from '../../../../horizon-components/MButton';

export const AuctionViewAbout = () => {
  const { t } = useTranslation(['translation']);
  const { auction } = useBidContext();
  const [isDescriptionShowMoreMode, setIsDescriptionShowMoreMode] = useState(false);

  const toggleDescriptionShowMoreMode = () => {
    setIsDescriptionShowMoreMode(!isDescriptionShowMoreMode);
  };

  const formattedContent =
    auction?.about && auction?.about.replace(/\r\n|\n/g, '<br />');

  if (!formattedContent?.length) return null;

  return (
    <div
      className={
        'rounded-2xl p-2 !shadow-[4px_4px_24px_4px_#7090B014] lg:rounded-3xl lg:p-4'
      }
    >
      <p className={'text-[16px] font-[600] text-gray-900'}>
        {t('bid.aboutThisLot')}
      </p>
      <div
        dangerouslySetInnerHTML={{
          __html: formattedContent,
        }}
        className={`text-[14px] font-[500] text-gray-900 ${
          !isDescriptionShowMoreMode &&
          formattedContent?.length > 350 &&
          'line-clamp-3'
        }`}
      />
      {formattedContent?.length > 350 && (
        <div className={'flex w-full justify-end'}>
          <MButton
            variant={'transparent'}
            color={'primary'}
            onClick={toggleDescriptionShowMoreMode}
          >
            {isDescriptionShowMoreMode ? t('bid.ShowLess') : t('bid.ShowMore')}
          </MButton>
        </div>
      )}
    </div>
  );
};
