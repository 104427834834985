import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Loader } from '../../../../components/Loader';
import { Modal } from '../../../../horizon-components/Modal';
import { BidStatusEnum } from '../../../../models/Auction';
import { useBidContext } from '../../../../providers/BidProvider/BidProvider';
import { AuctionViewBreadcrumbs } from './auction-view-breadcrumbs';
import { AuctionViewContent } from './auction-view-content';
import { AuctionViewPoster } from './auction-view-poster';
import { AuctionViewWinnerForm } from './auction-view-winner-form';

export const AuctionView: React.FC = () => {
  const { t } = useTranslation(['translation']);
  const { id } = useParams();
  const { auction, getWonAuctionInfo, fetchAuction, isLoading, fetchBids } =
    useBidContext();
  const [winnerFormModalIsOpen, setWinnerFormModalIsOpen] = useState(false);

  const { isAuctionWon, isRewardClaimed } = id
    ? getWonAuctionInfo(parseInt(id))
    : { isAuctionWon: false, isRewardClaimed: false };

  const handleFetch = useCallback(() => {
    if (id) {
      fetchAuction(id);
      fetchBids(id);
    }
  }, [fetchAuction, fetchBids, id]);

  useEffect(() => {
    handleFetch();
  }, [t]);

  useEffect(() => {
    if (
      !isRewardClaimed &&
      auction?.status === BidStatusEnum.COMPLETED &&
      isAuctionWon
    ) {
      setWinnerFormModalIsOpen(true);
    }
  }, [auction, isAuctionWon, isRewardClaimed]);

  return (
    <div>
      <div className="container mb-2.5 hidden md:block">
        <AuctionViewBreadcrumbs />
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className={'grid grid-cols-1 gap-4 pb-4 lg:grid-cols-2 lg:gap-3.5'}>
          <AuctionViewPoster onFetch={handleFetch} />
          <AuctionViewContent />

          {auction && (
            <Modal
              modalId={'winner-form-modal'}
              isOpen={winnerFormModalIsOpen}
              onClose={() => setWinnerFormModalIsOpen(false)}
              showClose={false}
              bodyClassName={`max-w-[520px]`}
            >
              <AuctionViewWinnerForm
                auctionId={Number(id || '0')}
                auction={auction}
                onClose={() => setWinnerFormModalIsOpen(false)}
              />
            </Modal>
          )}
        </div>
      )}
    </div>
  );
};
