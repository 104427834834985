import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { CoinIcon } from '../../components/CoinIcon';
import { Badge } from '../../horizon-components/Badge';
import { Auction } from '../../models/Auction';
import { formatNumber } from '../../shared/utils/formatNumber';
import { BidTime } from '../bid-time';

interface MiniAuctionCardProps {
  data: Auction;
  onFetch?: () => void;
  className?: string;
}

export const MiniAuctionCard = ({
  data,
  onFetch,
  className,
}: MiniAuctionCardProps) => {
  const { i18n } = useTranslation(['translation']);
  return (
    <div
      className={classNames(
        'relative inline-flex shrink grow basis-0 flex-col items-end justify-end gap-1 self-stretch',
        'min-h-[154px] w-full overflow-hidden rounded-xl p-1 lg:min-h-[308px]',
        className
      )}
      style={{
        backgroundImage: `url(${data.images?.[0]?.image})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="absolute bottom-1 right-1 inline-flex shrink grow basis-0 flex-col items-end justify-end gap-1 self-stretch rounded-xl">
        <Badge size="xl" className="pointer-events-none !hidden lg:!flex">
          <BidTime data={data} hideText onFetch={onFetch} />
        </Badge>
        <Badge variant="primary" active size="xl" className="!hidden lg:!flex">
          {data.highest_bid ? formatNumber(data.highest_bid, i18n.language) : 0}
          <CoinIcon className="fill-current relative !h-3.5 !w-3.5 stroke-0" />
        </Badge>
        <Badge variant="primary" active size="sm" className="!flex lg:!hidden">
          <BidTime data={data} hideText onFetch={onFetch} />
        </Badge>
      </div>
    </div>
  );
};
