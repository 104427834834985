import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { AuctionRoutes, MainRoutes } from './types/routes';
import { Navigate } from '../../../horizon-components/Navigate';
import { BidProvider } from '../../../providers/BidProvider/BidProvider';
import { NotFoundPage } from '../../../components/NotFoundPage';
import { BidAllPage } from '../../../modules/Bid/bid-all';
import { BidAboutPage } from '../../../modules/Bid/bid-about';
import { BidWinningsPage } from '../../../modules/Bid/bid-winnings';
import { AuctionView } from '../../../modules/Bid/auction-view/ui/auction-view';
import { AuctionViewBidsPage } from '../../../modules/Bid/auction-view';

export const BidRouter: React.FC = () => {
  return (
    <BidProvider>
      <div className="mx-auto w-full grow sm-max:p-2">
        <div className="mx-auto w-full grow">
          <Routes>
            <Route
              path="/"
              element={
                <Navigate to={MainRoutes.bid + AuctionRoutes.actual} replace />
              }
            />
            <Route path={AuctionRoutes.actual} element={<BidAllPage />} />
            <Route path={AuctionRoutes.about} element={<BidAboutPage />} />
            <Route path={AuctionRoutes.winnings} element={<BidWinningsPage />} />
            <Route
              path={AuctionRoutes.auctionView + '/:id'}
              element={<AuctionView />}
            />
            <Route
              path={AuctionRoutes.winningsView + '/:id'}
              element={<AuctionView />}
            />
            <Route
              path={AuctionRoutes.auctionView + '/:id' + AuctionRoutes.bids}
              element={<AuctionViewBidsPage />}
            />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </div>
      </div>
    </BidProvider>
  );
};
