import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useNavigate } from '../../../hooks/useNavigate';
import {
  setInAppBrowserModeAndroid,
  setInAppBrowserModeIOS,
} from '../../../utils/getPlatform';
import { MAIN_PAGE_ROUTE } from '../../../horizon-layout/MainLayout/Routes/types/routes';

type Props = {
  originPlatform: 'ios' | 'android';
};

export const InAppBrowserMode = ({ originPlatform }: Props) => {
  const [params] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    originPlatform === 'ios'
      ? setInAppBrowserModeIOS()
      : setInAppBrowserModeAndroid();

    const originPage = params.get('originPage');
    if (!originPage) {
      navigate(MAIN_PAGE_ROUTE);
    } else {
      navigate(originPage);
    }
  }, []);

  return null;
};
