import { Capacitor } from '@capacitor/core';
import { PlatformsEnum } from '../entities/Platforms/platforms';

export const IN_APP_BROWSER_MODE_STORAGE_KEY = 'isInAppBrowser';

export function setInAppBrowserModeIOS() {
  localStorage.setItem(IN_APP_BROWSER_MODE_STORAGE_KEY, 'ios');
}

export function setInAppBrowserModeAndroid() {
  localStorage.setItem(IN_APP_BROWSER_MODE_STORAGE_KEY, 'android');
}

function getPlatform() {
  const platform = Capacitor.getPlatform() as PlatformsEnum;
  const telegramObject = window.Telegram?.WebApp;
  const inAppBrowserModeIOS =
    localStorage.getItem(IN_APP_BROWSER_MODE_STORAGE_KEY) === 'ios';
  const inAppBrowserModeAndroid =
    localStorage.getItem(IN_APP_BROWSER_MODE_STORAGE_KEY) === 'android';

  if (!!telegramObject) {
    return PlatformsEnum.TELEGRAM;
  }
  if (inAppBrowserModeAndroid) {
    return PlatformsEnum.IN_APP_BROWSER_ANDROID;
  }
  if (inAppBrowserModeIOS) {
    return PlatformsEnum.IN_APP_BROWSER_IOS;
  }
  return platform;
}

export default getPlatform;
