import { useEffect, useMemo, useState } from 'react';
import { Modal } from '../../../../horizon-components/Modal';
import { AuctionViewWinner } from './auction-view-winner';
import { useTranslation } from 'react-i18next';
import { useBidContext } from '../../../../providers/BidProvider/BidProvider';
import { useParams } from 'react-router-dom';
import { BidStatusEnum } from '../../../../models/Auction';

export const AuctionViewBidsWinnerModal = () => {
  const { t } = useTranslation(['translation']);
  const { id } = useParams();
  const [winnerModalIsOpen, setWinnerModalIsOpen] = useState(false);
  const { auction, bids, getWonAuctionInfo } = useBidContext();

  const { isAuctionWon, isRewardClaimed } = id
    ? getWonAuctionInfo(parseInt(id))
    : { isAuctionWon: false, isRewardClaimed: false };

  const winners = bids?.results?.filter((item) => item.is_winner) || [];
  const isMultiWinners = useMemo(() => winners?.length > 1, [winners]);

  useEffect(() => {
    if (
      auction?.status === BidStatusEnum.COMPLETED &&
      !(!isRewardClaimed && isAuctionWon)
    ) {
      winners?.length > 0 && setWinnerModalIsOpen(true);
    }
  }, [auction, isAuctionWon, isRewardClaimed, winners?.length]);

  const handleToggle = () => {
    setWinnerModalIsOpen(!winnerModalIsOpen);
  };

  if (!auction?.id || !winners?.length) return null;

  return (
    <Modal
      modalId={'winned-modal'}
      isOpen={winnerModalIsOpen}
      onClose={handleToggle}
      title={isMultiWinners ? t('bid.Our_winners') : t('bid.Our_winner')}
      bodyClassName={`max-w-[390px]`}
    >
      <AuctionViewWinner
        isMultiWinners={isMultiWinners}
        winners={winners}
        auction={auction}
        onClose={handleToggle}
      />
    </Modal>
  );
};
