import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AppleCallback from '../../../modules/AuthNew/AppleCallback/AppleCallback';
import ChangePassword from '../../../modules/AuthNew/ChangePassword/ChangePassword';
import CheckCode from '../../../modules/AuthNew/CheckCode/CheckCode';
import GoogleCallback from '../../../modules/AuthNew/GoogleCallback/GoogleCallback';
import ResetPassword from '../../../modules/AuthNew/ResetPassword/ResetPassword';
import SignIn from '../../../modules/AuthNew/SignIn/SignIn';
import SignUp from '../../../modules/AuthNew/SignUp/SignUp';
import { ProfileRouter } from './ProfileRouter';
import { AuthRoutes, MainRoutes } from './types/routes';
import { NotFoundPage } from '../../../components/NotFoundPage';
import TelegramCallback from '../../../modules/AuthNew/TelegramCallback/TelegramCallback';
import { InAppBrowserMode } from '../../../modules/AuthNew/InAppBrowserMode/InAppBrowserMode';
import { InAppBrowserModeCallback } from '../../../modules/AuthNew/InAppBrowserModeCallback/InAppBrowserModeCallback';

export const AuthRouter: React.FC = () => {
  return (
    <Routes>
      <Route path={MainRoutes.auth} element={<SignIn />} />
      <Route path={AuthRoutes.signin} element={<SignIn />} />
      <Route path={AuthRoutes.signup} element={<SignUp />} />
      <Route path={AuthRoutes.reset} element={<ResetPassword />} />
      <Route path={AuthRoutes.change} element={<ChangePassword />} />
      <Route path={AuthRoutes.code} element={<CheckCode />} />
      <Route
        path={AuthRoutes['google-signin-signup']}
        element={<GoogleCallback />}
      />
      <Route path={AuthRoutes['apple-signin-signup']} element={<AppleCallback />} />
      <Route
        path={AuthRoutes['telegram-oauth-submit']}
        element={<TelegramCallback />}
      />
      <Route path={AuthRoutes.profile + '/*'} element={<ProfileRouter />} />

      <Route
        path={AuthRoutes['in-app-browser-mode-android']}
        element={<InAppBrowserMode originPlatform={'android'} />}
      />
      <Route
        path={AuthRoutes['in-app-browser-mode-ios']}
        element={<InAppBrowserMode originPlatform={'ios'} />}
      />
      <Route
        path={AuthRoutes['in-app-browser-mode-callback']}
        element={<InAppBrowserModeCallback />}
      />

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
