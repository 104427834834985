import EdresCoinImage from '../../assets/images/reward/erp.png';
import { MButton } from '../../horizon-components/MButton';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Share } from '../Share/Share';
import { useShareReferal } from '../../hooks/useShareReferal';
import { Modal } from '../../horizon-components/Modal';

export const CongratulationsModal = ({
  title,
  description,
  isModalOpen,
  onClose,
}: {
  title: string;
  description: string;
  isModalOpen: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation(['translation']);
  const { url, activationRewardAmount } = useShareReferal();

  return (
    <Modal isOpen={isModalOpen} onClose={onClose}>
      <div className={'flex flex-col items-center gap-[15px]'}>
        <img src={EdresCoinImage} alt="edres coin" className={'w-[344px]'} />
        <div className={'flex flex-col gap-[13px] text-center'}>
          <span className={'text-[24px] font-bold text-primary-purple-blue-500'}>
            {title}
          </span>
          <div className={'max-w-[350px] md:max-w-full'}>
            <span className={'text-[16px] font-medium text-secondary-grey-900'}>
              {description}
            </span>
          </div>
        </div>
        <Share
          url={url}
          loading={!url}
          text={t('main.share.description', {
            activationRewardAmount,
          })}
        />
        <MButton
          color={'primary'}
          size={'sm'}
          variant={'highlighted'}
          onClick={onClose}
          className={'w-full'}
        >
          {t('buttons.Okay')}
        </MButton>
      </div>
    </Modal>
  );
};
