import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { MButton } from '../../horizon-components/MButton';
import useFetch from '../../hooks/useFetch';
import { useCallback, useEffect, useRef } from 'react';
import { BidStatusEnum } from '../../models/Auction';
import { getAuctions } from '../../api/bid';
import { MiniAuctionCard } from '../mini-auction-card';
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow.svg';
import { Link } from '../../horizon-components/Link';
import { MainRoutes } from '../../horizon-layout/MainLayout/Routes/types/routes';
import { Loader } from '../../components/Loader';

interface BidMainPageCardProps {
  className?: string;
}

export const BidMainPageCard = ({ className }: BidMainPageCardProps) => {
  const { t } = useTranslation(['translation']);
  const hasFetched = useRef(false);

  const { data: actual, isLoading, fetchData: fetchActual } = useFetch(getAuctions);

  const handleFetch = useCallback(() => {
    if (!hasFetched.current) {
      fetchActual(BidStatusEnum.ACTIVE);
      hasFetched.current = true;
      setTimeout(() => {
        hasFetched.current = false;
      }, 5000);
    }
  }, [fetchActual]);

  useEffect(() => {
    hasFetched.current = false;
    handleFetch();
  }, [t, handleFetch]);

  if (!actual?.results.length) return null;

  return (
    <Link
      to={MainRoutes.bid}
      className={classNames(
        'inline-flex h-full flex-col items-start justify-start gap-3 rounded-2xl border border-[#e0e5f2] bg-[#f6f8fd] p-3 lg:gap-4 lg:p-6',
        className
      )}
    >
      <div className="flex flex-col items-start justify-start gap-1 self-stretch lg:gap-2">
        <h3 className="self-stretch text-base font-bold text-[#4318ff] lg:text-3xl">
          {t('main.Win')}
        </h3>
        <p className="self-stretch text-sm font-semibold leading-snug text-[#11047a] lg:text-lg lg:font-medium lg:text-[#1b2559]">
          {t('main.Spend_your_earned_tokens_on_real_auction_winnings')}
        </p>
      </div>
      <div className="inline-flex shrink grow basis-0 items-start justify-start gap-3 self-stretch">
        {isLoading ? (
          <Loader />
        ) : (
          actual.results
            .slice(0, 2)
            .map((auction) => (
              <MiniAuctionCard
                key={auction.end_datetime}
                data={auction}
                onFetch={handleFetch}
                className={
                  actual.results.slice(0, 2).length === 1
                    ? 'min-h-[230px] lg:min-h-[360px]'
                    : ''
                }
              />
            ))
        )}
      </div>
      <MButton variant="highlighted" className="w-full">
        {t('buttons.Participate_in_the_auction')}
        <ArrowIcon className="Icon relative h-5 w-5" />
      </MButton>
    </Link>
  );
};
