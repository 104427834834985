import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useNavigate } from '../../../../hooks/useNavigate';
import { MButton } from '../../../../horizon-components/MButton';
import { Modal } from '../../../../horizon-components/Modal';
import {
  AuctionRoutes,
  MainRoutes,
} from '../../../../horizon-layout/MainLayout/Routes/types/routes';
import { useBidContext } from '../../../../providers/BidProvider/BidProvider';
import { AuctionViewBidsAll } from './auction-view-bids-all';

export const AuctionViewBidsAllModal = () => {
  const { t } = useTranslation(['translation']);
  const { id } = useParams();
  let navigate = useNavigate();
  const [isAllBidsMode, setIsAllBidsMode] = useState(false);
  const { bids, setIsSendBidMode, setActualBid } = useBidContext();

  const handleBid = (bid_amount: number) => {
    setIsAllBidsMode(false);
    setIsSendBidMode(true);
    setActualBid(bid_amount + 1);
  };

  const handleToggle = () => {
    setIsAllBidsMode(!isAllBidsMode);
  };

  const goTo = () =>
    navigate(
      MainRoutes.bid + AuctionRoutes.auctionView + `/${id}` + AuctionRoutes.bids
    );

  if (!bids?.results.length) return null;

  return (
    <>
      <div className={'flex w-full justify-end'}>
        <MButton
          variant={'transparent'}
          color={'primary'}
          onClick={() => (window.innerWidth >= 768 ? handleToggle() : goTo())}
        >
          {t('bid.Show_all_bets')}
        </MButton>
      </div>
      {isAllBidsMode && (
        <Modal
          modalId={'bids-full-modal'}
          isOpen={isAllBidsMode}
          onClose={handleToggle}
          title={t('bid.allBids')}
          bodyClassName={`max-w-[390px]`}
        >
          <AuctionViewBidsAll handleBid={handleBid} />
        </Modal>
      )}
    </>
  );
};
