import React, { useEffect, useRef } from 'react';
import { generateTelegramRedirectUri } from '../SignIn/utils';
import { useLanguage } from '../../../providers';

const authBotUsername = process.env.REACT_APP_TG_BOT_NAME;

export interface CreateTelegramAuthScriptOptions {
  authCallbackUrl: string;
  botUsername: string;
  buttonSize?: 'large' | 'medium' | 'small';
  cornerRadius?: number;
  lang?: string;
  requestAccess?: 'write' | null;
  showAvatar?: boolean;
  widgetVersion?: number | string;
}

/**
 * Script tag creation of telegram login widget
 * TODO: Discuss about options to auth user (callbackURI or callback function)
 * @see https://core.telegram.org/widgets/login
 */
export const createTelegramAuthScript = ({
  authCallbackUrl,
  botUsername,
  buttonSize = 'large',
  cornerRadius = 16,
  lang = 'en',
  requestAccess = 'write',
  showAvatar = true,
  widgetVersion = 22,
}: CreateTelegramAuthScriptOptions): HTMLScriptElement => {
  const script = document.createElement('script');

  script.async = true;

  script.src = `https://telegram.org/js/telegram-widget.js?${widgetVersion}`;
  script.setAttribute('data-telegram-login', botUsername);
  script.setAttribute('data-size', buttonSize);
  if (cornerRadius) {
    script.setAttribute('data-radius', `${cornerRadius}`);
  }
  if (requestAccess) {
    script.setAttribute('data-request-access', requestAccess);
  }
  script.setAttribute('data-userpic', JSON.stringify(Boolean(showAvatar)));
  script.setAttribute('data-lang', lang);

  if (authCallbackUrl) {
    script.setAttribute('data-auth-url', authCallbackUrl);
  }

  return script;
};

export const TelegramAuth: React.FC = () => {
  const { language } = useLanguage();

  const wrapperRef = useRef<HTMLDivElement>(null);
  const scriptRef = useRef<HTMLScriptElement>();

  useEffect(() => {
    if (!authBotUsername) return;
    scriptRef.current = createTelegramAuthScript({
      authCallbackUrl: generateTelegramRedirectUri(),
      botUsername: authBotUsername,
      lang: language,
    });
    wrapperRef.current?.append(scriptRef.current);
    return () => {
      scriptRef.current?.remove();
    };
  }, []);

  if (!authBotUsername) {
    console.warn('Telegram auth bot user name not found');
    return null;
  }

  return (
    <div ref={wrapperRef} className="flex w-full items-center justify-center" />
  );
};
