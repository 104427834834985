import instance, { AUTH_API_URL } from './instance';
import {
  Auction,
  Bid,
  BidStatusEnum,
  MyBidsResponse,
  MyWonAuctionsResponse,
} from '../models/Auction';
import { ResponsePagination } from '../models/Response';

export function getAuctions(
  status?: BidStatusEnum,
  page?: Number,
  my_auctions?: boolean,
  page_size?: Number
): Promise<ResponsePagination<Auction>> {
  return instance()
    .get(`${AUTH_API_URL}/public/auction/auctions/`, {
      params: { status, page, my_auctions, page_size },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getMyBids(): Promise<MyBidsResponse> {
  return instance()
    .get(`${AUTH_API_URL}/private/auction/my-bids/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getWonAuctions(): Promise<MyWonAuctionsResponse> {
  return instance()
    .get(`${AUTH_API_URL}/private/auction/my-won-auctions/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getAuctionById(id: string): Promise<Auction> {
  return instance()
    .get(`${AUTH_API_URL}/public/auction/auctions/${id}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getBids(
  auctionId: string,
  page: number = 1
): Promise<ResponsePagination<Bid>> {
  return instance()
    .get(`${AUTH_API_URL}/public/auction/bids/`, {
      params: { auction: auctionId, page },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function placeBid(id: number, amount: string): Promise<void> {
  return instance()
    .post(`${AUTH_API_URL}/private/auction/place-bid/`, {
      bid_amount: amount,
      auction: id,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function placeBidList(auctionId: number): Promise<Bid[]> {
  return instance()
    .get(`/place-bids/${auctionId}/list/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function winnerClaimCreate(
  id: number,
  email: string,
  phone_number: string
): Promise<void> {
  return instance()
    .post(`${AUTH_API_URL}/private/auction/claim-prize/`, {
      email,
      phone_number,
      auction: id,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function setAuctionNotifications(
  auction: number
): Promise<{ auction: number; id: number }> {
  return instance()
    .post(`/auction-notifications/`, { auction })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function deleteAuctionNotifications(auction: number): Promise<void> {
  return instance()
    .delete(`/auction-notifications/${auction}`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}
