import { lazy } from 'react';

export const AuctionViewPage = lazy(() =>
  import('./ui/auction-view').then(({ AuctionView }) => ({
    default: AuctionView,
  }))
);

export const AuctionViewBidsPage = lazy(() =>
  import('./ui/auction-view-bids-page').then(({ AuctionViewBidsPage }) => ({
    default: AuctionViewBidsPage,
  }))
);
