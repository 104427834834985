import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Performance } from '../../../modules/Wiz/Performance/Performance';
import { Quiz } from '../../../modules/Wiz/Quiz';
import { Quizzes } from '../../../modules/Wiz/Quizzes/Quizzes';
import { Scoreboard } from '../../../modules/Wiz/Scoreboard/Scoreboard';
import { WizProvider } from '../../../providers';
import { MainRoutes, WizRoutes } from './types/routes';
import { Navigate } from '../../../horizon-components/Navigate';
import { NotFoundPage } from '../../../components/NotFoundPage';

export const WizRouter: React.FC = () => {
  return (
    <div className="mx-auto w-full grow sm-max:p-2">
      <Routes>
        <Route
          path="/"
          element={<Navigate to={MainRoutes.wiz + WizRoutes.quiz} replace />}
        />
        <Route
          path={WizRoutes.quiz}
          element={
            <WizProvider>
              <Quiz />
            </WizProvider>
          }
        />
        {/*<Route path={WizRoutes.quizzes} element={<Quizzes />} />*/}
        {/*<Route path={WizRoutes.performance} element={<Performance />} />*/}
        {/*<Route path={WizRoutes.scoreboard} element={<Scoreboard />} />*/}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
};
