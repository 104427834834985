import { ReactComponent as ArticleIcon } from '../../assets/icons/feed/article.svg';
import { ReactComponent as ImageIcon } from '../../assets/icons/feed/image.svg';
import { ReactComponent as LinkIcon } from '../../assets/icons/feed/link.svg';
import { ReactComponent as Key } from '../../assets/icons/key.svg';
import { ReactComponent as AboutIcon } from '../../assets/icons/mobile/about.svg';
import { ReactComponent as ActualIcon } from '../../assets/icons/mobile/actual.svg';
import { ReactComponent as MyCoursesIcon } from '../../assets/icons/mobile/book.svg';
import { ReactComponent as DailyIcon } from '../../assets/icons/mobile/calendar.svg';
import { ReactComponent as CoursesIcon } from '../../assets/icons/mobile/cap.svg';
import { ReactComponent as CommunitiesIcon } from '../../assets/icons/mobile/group.svg';
import { ReactComponent as HomeIcon } from '../../assets/icons/mobile/home.svg';
import { ReactComponent as FeedsIcon } from '../../assets/icons/mobile/newspaper.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/mobile/plus.svg';
import { ReactComponent as ProfileIcon } from '../../assets/icons/mobile/profile.svg';
import { ReactComponent as LeaderboardIcon } from '../../assets/icons/mobile/star.svg';
import { ReactComponent as SubjectsIcon } from '../../assets/icons/mobile/ticket.svg';
import { ReactComponent as PerformanceIcon } from '../../assets/icons/mobile/trending.svg';
import { ReactComponent as WinningsIcon } from '../../assets/icons/mobile/winnings.svg';
import { ReactComponent as WizIcon } from '../../assets/icons/mobile/wiz.svg';
import { ReactComponent as BidIcon } from '../../assets/icons/mobile/actual.svg';

import { Services } from '../../modules/Services';
import {
  AuctionRoutes,
  AuthRoutes,
  EduRoutes,
  MainRoutes,
  ProfileRoutes,
  WeberRoutes,
  WizRoutes,
} from './Routes/types/routes';

export const mainRoutes = [
  {
    name: 'Home',
    layout: 'menu-home',
    path: '/',
    icon: <HomeIcon />,
    collapse: false,
  },
  {
    name: 'WIZ',
    layout: 'menu-wiz',
    path: MainRoutes.wiz,
    icon: <WizIcon />,
    collapse: false,
  },
  {
    name: 'ED',
    layout: 'menu-ed',
    path: MainRoutes.edu,
    icon: <CoursesIcon />,
    collapse: false,
  },
  {
    name: 'BID',
    layout: 'menu-bid',
    path: MainRoutes.bid,
    icon: <BidIcon />,
    collapse: false,
  },
  {
    name: 'Profile',
    layout: 'menu-profile',
    path: MainRoutes.auth + AuthRoutes.profile + ProfileRoutes.user,
    icon: <ProfileIcon />,
    collapse: false,
    component: <Services />,
    auth: true,
  },
];

export const userRoutes = [
  {
    name: 'Student',
    collapse: true,
    items: [
      {
        name: 'DigitalPass',
        path: MainRoutes.edu + EduRoutes['digital-passes'],
        icon: <SubjectsIcon />,
        collapse: false,
      },
      {
        name: 'Courses',
        path: MainRoutes.edu + EduRoutes.courses,
        icon: <CoursesIcon />,
        collapse: false,
      },
    ],
  },
  {
    name: 'Teacher',
    collapse: true,
    items: [
      {
        name: 'MyCourses',
        path: MainRoutes.edu + EduRoutes['my-courses'],
        icon: <MyCoursesIcon />,
        collapse: false,
      },
      {
        name: 'VerifyCourse',
        path: MainRoutes.edu + EduRoutes.verify,
        icon: <Key className="h-5 w-5 text-gray-400" />,
        collapse: false,
        isStaffRoute: true,
      },
    ],
  },
];

export const eduRoutes = [
  {
    name: 'DigitalPass',
    path: MainRoutes.edu + EduRoutes['digital-passes'],
    icon: <SubjectsIcon />,
    collapse: false,
  },
  {
    name: 'Courses',
    path: MainRoutes.edu + EduRoutes.courses,
    icon: <CoursesIcon />,
    collapse: false,
  },
  {
    name: 'MyCourses',
    path: MainRoutes.edu + EduRoutes['my-courses'],
    icon: <MyCoursesIcon />,
    collapse: false,
  },
  {
    name: 'VerifyCourse',
    path: MainRoutes.edu + EduRoutes.verify,
    icon: <Key />,
    collapse: false,
    isStaffRoute: true,
  },
];

export const weberRoutes = [
  {
    name: 'Feed',
    path: MainRoutes.weber + WeberRoutes.feeds,
    icon: <FeedsIcon />,
    collapse: false,
  },
  {
    name: 'Create post',
    icon: <PlusIcon />,
    collapse: false,
    secondary: true,
    items: [
      {
        name: 'Post with attachements',
        path: MainRoutes.weber + WeberRoutes['feed-create-attachments'],
        icon: <ImageIcon />,
        collapse: false,
        component: 'Share content with images and docs',
      },
      {
        name: 'Post with link',
        path: MainRoutes.weber + WeberRoutes['feed-create-link'],
        icon: <LinkIcon />,
        collapse: false,
        component: 'Publish link to your article',
      },
      {
        name: 'Article',
        path: MainRoutes.weber + WeberRoutes['feed-create-article'],
        icon: <ArticleIcon />,
        collapse: false,
        component: 'Write your article with large content',
      },
    ],
  },
  {
    name: 'Communities',
    path: MainRoutes.weber + WeberRoutes.communities,
    icon: <CommunitiesIcon />,
    collapse: false,
  },
];

export const wizRoutes = [
  {
    name: 'DailyChallenge',
    path: MainRoutes.wiz + WizRoutes.quizzes,
    icon: <DailyIcon />,
    collapse: false,
  },
  {
    name: 'Performance',
    path: MainRoutes.wiz + WizRoutes.performance,
    icon: <PerformanceIcon />,
    collapse: false,
  },
  {
    name: 'Scoreboard',
    path: MainRoutes.wiz + WizRoutes.scoreboard,
    icon: <LeaderboardIcon />,
    collapse: false,
  },
];

export const bidRoutes = [
  {
    name: 'All_auctions',
    path: MainRoutes.bid + AuctionRoutes.actual,
    icon: <ActualIcon />,
    collapse: false,
  },
  {
    name: 'About',
    path: MainRoutes.bid + AuctionRoutes.about,
    icon: <AboutIcon />,
    collapse: false,
  },
  {
    name: 'Winnings',
    path: MainRoutes.bid + AuctionRoutes.winnings,
    icon: <WinningsIcon />,
    collapse: false,
  },
];
