import { User } from './User';

export enum BidStatusEnum {
  ACTIVE = 'active',
  COMPLETED = 'completed',
  PLANNED = 'planned',
}

export type Bid = {
  bid_amount: number;
  id: number;
  is_winner: boolean;
  created_at: string;
  user: User;
};

export type Auction = {
  about: string;
  bidders_count: number;
  end_datetime: string;
  id: number;
  images: { image: string }[];
  highest_bid: string;
  number_winners: number;
  start_datetime: string;
  status: BidStatusEnum;
  step: number;
  title: string;
};

export type MyBidsResponse = {
  my_bids: Record<number, Bid>;
};

export type MyWonAuctionsResponse = {
  won_auctions: Record<string, boolean>;
};
