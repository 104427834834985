import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { BidStatusEnum } from '../../../../models/Auction';
import { useUserContext } from '../../../../providers';
import { useBidContext } from '../../../../providers/BidProvider/BidProvider';
import { BidCard } from '../../../../widgets/bid-card';

export const AuctionViewBidsMy = () => {
  const { t } = useTranslation(['translation']);
  const { id } = useParams();
  const { user } = useUserContext();
  const { auction, bids, getMyBidForAuction } = useBidContext();
  const winnersLanght = (bids?.results?.filter((bid) => bid.is_winner) || [])
    ?.length;
  const number_winners =
    auction?.number_winners &&
    winnersLanght < auction?.number_winners &&
    auction?.status === BidStatusEnum.COMPLETED
      ? winnersLanght
      : auction?.number_winners || 0;

  const userBid = bids?.results?.findIndex((bid) => bid.user.guid === user?.guid);
  const myBid = id ? getMyBidForAuction(parseInt(id)) : null;

  if (
    !myBid?.bid_amount ||
    (typeof userBid !== 'undefined' && userBid > -1
      ? !(userBid + 1 > number_winners && userBid !== 0)
      : false)
  )
    return null;

  return (
    <>
      <div className={'flex w-full items-center justify-between'}>
        <p className={'text-[16px] font-[600] text-gray-900'}>{t('bid.My_bet')}</p>
      </div>
      <BidCard
        bid={myBid}
        place={
          typeof userBid !== 'undefined'
            ? (userBid + 1 !== number_winners && userBid !== 0) ||
              userBid > number_winners
              ? userBid + 1
              : undefined
            : undefined
        }
        isMy
        isWin={
          auction?.status === BidStatusEnum.ACTIVE
            ? typeof userBid !== 'undefined' && userBid < number_winners
            : auction?.status === BidStatusEnum.COMPLETED
              ? typeof userBid !== 'undefined' &&
                userBid > 0 &&
                userBid < number_winners
              : undefined
        }
        canBid={
          typeof userBid !== 'undefined' &&
          auction?.status === BidStatusEnum.ACTIVE &&
          userBid >= number_winners
        }
      />
    </>
  );
};
