import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserContext } from '../../../../providers';
import { useBidContext } from '../../../../providers/BidProvider/BidProvider';
import { BidCard } from '../../../../widgets/bid-card';

export const AuctionViewBidsOtherList = () => {
  const { t } = useTranslation(['translation']);
  const { user } = useUserContext();
  const { auction, bids } = useBidContext();
  const number_winners = auction?.number_winners || 0;

  const userBid = bids?.results?.findIndex((bid) => bid.user.guid === user?.guid);

  const others = useMemo(
    () =>
      bids?.results.filter((item, idx) => {
        if (
          typeof userBid !== 'undefined' &&
          userBid > 0 &&
          userBid + 1 <= number_winners
        ) {
          return (
            idx === 0 || (idx + 1 <= number_winners && user?.guid === item.user.guid)
          );
        }
        return idx === 0 || idx + 1 === number_winners;
      }) || [],
    [bids?.results, userBid, number_winners, user?.guid]
  );

  if (!others.length) return null;

  return (
    <>
      <div className={'flex w-full items-center justify-between'}>
        <p className={'text-[16px] font-[600] text-gray-900'}>
          {t('bid.Winning_bets')}
        </p>
        <div
          className={
            'rounded-full bg-purple-100 px-[8px] py-[2px] text-[12px] font-[600] text-purple-400'
          }
        >
          {bids?.count}
        </div>
      </div>
      {others?.map((bid, idx) => (
        <BidCard
          bid={bid}
          place={idx + 1}
          isMy={bid.user.guid === user?.guid}
          isWin={bid.user.guid !== user?.guid}
          isMin={idx + 1 === 2}
          key={idx}
        />
      ))}
    </>
  );
};
