import {
  AuthRoutes,
  MainRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import getPlatform from '../../../utils/getPlatform';
import { PlatformsEnum } from '../../../entities/Platforms/platforms';

export const generateGoogleRedirectUri = () => {
  const redirectPage = AuthRoutes['google-signin-signup'];
  const platform = getPlatform();
  let redirectURI = `${process.env.REACT_APP_API_URL}${MainRoutes.auth}${redirectPage}/`;
  if (
    [
      PlatformsEnum.ANDROID,
      PlatformsEnum.IOS,
      PlatformsEnum.IN_APP_BROWSER_IOS,
      PlatformsEnum.IN_APP_BROWSER_ANDROID,
    ].includes(platform)
  ) {
    redirectURI = `${process.env.REACT_APP_API_AUTHURL}${MainRoutes.auth}${redirectPage}/`;
  }
  return redirectURI;
};

export const generateAppleRedirectUri = () => {
  let redirectURI = `${process.env.REACT_APP_API_URL}${MainRoutes.auth}${AuthRoutes['apple-signin-signup']}/`;
  const platform = getPlatform();
  if (
    [
      PlatformsEnum.IOS,
      PlatformsEnum.IN_APP_BROWSER_IOS,
      PlatformsEnum.IN_APP_BROWSER_ANDROID,
    ].includes(platform)
  ) {
    redirectURI = `${process.env.REACT_APP_API_AUTHURL}${MainRoutes.auth}${AuthRoutes['apple-signin-signup']}/`;
  }
  return redirectURI;
};

export const generateTelegramRedirectUri = () => {
  const postfix = `${MainRoutes.auth}${AuthRoutes['telegram-oauth-submit']}`;
  const platform = getPlatform();
  let redirectURI = `${process.env.REACT_APP_API_URL}${postfix}`;
  if (
    [
      PlatformsEnum.ANDROID,
      PlatformsEnum.IOS,
      PlatformsEnum.IN_APP_BROWSER_ANDROID,
      PlatformsEnum.IN_APP_BROWSER_IOS,
    ].includes(platform)
  ) {
    redirectURI = `${process.env.REACT_APP_API_AUTHURL}${postfix}/`;
  }

  return redirectURI;
};
