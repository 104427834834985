import { Share, ShareOptions } from '@capacitor/share';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { PlatformsEnum } from '../entities/Platforms/platforms';
import {
  AuthRoutes,
  MainRoutes,
} from '../horizon-layout/MainLayout/Routes/types/routes';
import { useUserContext } from '../providers';
import { useRewardContext } from '../providers/RewardProvider';
import { useNavigate } from './useNavigate';
import getPlatform from '../utils/getPlatform';

export const useShareReferal = () => {
  const { t, i18n } = useTranslation(['translation']);
  const { user, referral } = useUserContext();
  const { rewards } = useRewardContext();
  const navigate = useNavigate();
  const platform = getPlatform();

  const activationRewardAmountRaw =
    (rewards &&
      rewards
        .find((reward) => reward.tokenomic_type === 'referral_code_activation')
        ?.value.toLocaleString()) ||
    '0';
  const activationRewardAmount = parseInt(
    activationRewardAmountRaw
  ).toLocaleString();

  const isMobile = useCallback(() => {
    const userAgent = navigator.userAgent || navigator.vendor;
    return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
      userAgent
    );
  }, []);

  const prepareURL = useCallback((url: string, ref: string) => {
    const turl = new URL(url);
    turl.hostname =
      process.env.REACT_APP_API_URL?.replace('https://', '') || turl.hostname;
    turl.searchParams.append('ref-code', ref);
    return turl.href;
  }, []);
  const prepareTelegramURL = useCallback((ref: string) => {
    return `https://t.me/${process.env.REACT_APP_TG_BOT_NAME}/${process.env.REACT_APP_TG_APP_NAME}?startapp=refid${ref}`;
  }, []);

  const handleShare = useCallback(async () => {
    if (!referral) return null;

    if (platform === PlatformsEnum.TELEGRAM) {
      window.location.href = `https://t.me/share/url?url=${prepareTelegramURL(referral)}&text=${t('main.share.description', { activationRewardAmount })}`;
    } else if (isMobile() && navigator.share) {
      await navigator.share({
        title: document.title,
        text: `${t('main.share.description', { activationRewardAmount })} ${prepareURL(window.location.href, referral)}`,
        // url: prepareURL(window.location.href, referral),
      });
    } else if (
      platform === PlatformsEnum.IOS ||
      platform === PlatformsEnum.ANDROID
    ) {
      const shareParams: ShareOptions = {
        title: document.title,
        text: `${t('main.share.description', { activationRewardAmount })} ${prepareURL(window.location.href, referral)}`,
      };
      if (platform === PlatformsEnum.ANDROID) {
        shareParams.dialogTitle = t('main.Share_Edres_for_friends');
      }
      await Share.share(shareParams);
    } else {
      const domain = `${window.location.protocol}//${window.location.hostname}`;
      try {
        await navigator.clipboard.writeText(
          prepareURL(`${domain}/${i18n.language}/`, referral)
        );
        toast(t('main.The_referral_has_been_copied'), { type: 'info' });
      } catch (err) {
        console.error('Failed to write to clipboard:', err);
      }
    }
  }, [isMobile, prepareURL, referral, t, activationRewardAmount]);

  const handleClick = useCallback(async () => {
    if (!user) {
      navigate(MainRoutes.auth + AuthRoutes.signin);
      return;
    }
    if (referral) {
      await handleShare();
    }
  }, [user, referral]);

  return {
    handleClick,
    url:
      platform === PlatformsEnum.TELEGRAM
        ? referral
          ? prepareTelegramURL(referral)
          : ''
        : referral
          ? prepareURL(window.location.href, referral)
          : '',
    activationRewardAmount,
  };
};
