import { useTranslation } from 'react-i18next';
import { AuctionViewPlaceBid } from './auction-view-place-bid';
import { useBidContext } from '../../../../providers/BidProvider/BidProvider';
import { BottomSheetModal } from '../../../../horizon-components/bottom-sheet-modal';

export const AuctionViewRate = ({ onBid }: { onBid?: () => void }) => {
  const { t } = useTranslation(['translation']);
  const { setIsSendBidMode, isSendBidMode } = useBidContext();

  const handleToggle = () => {
    setIsSendBidMode(!isSendBidMode);
  };

  return (
    <>
      <BottomSheetModal
        close={handleToggle}
        opened={isSendBidMode}
        title={t('bid.Place_a_bet')}
      >
        <AuctionViewPlaceBid onShowBet={handleToggle} onBid={onBid} />
      </BottomSheetModal>
    </>
  );
};
