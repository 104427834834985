import { AxiosError } from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import { getDocsAgree, signUp } from '../../../api/auth';
import { PlatformsEnum } from '../../../entities/Platforms/platforms';
import useForm, { ErrorMessages } from '../../../hooks/useForm';
import { useNavigate } from '../../../hooks/useNavigate';
import { MButton } from '../../../horizon-components/MButton/ui/MButton';
import { MInput } from '../../../horizon-components/MInput/MInput';
import {
  AuthRoutes,
  MAIN_PAGE_ROUTE,
  MainRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { TAgree } from '../../../models/Main';
import { useUserContext } from '../../../providers';
import { AuthLayout } from '../../../widgets/AuthLayout';
import { AppleAuth } from '../AppleAuth/AppleAuth';
import { GoogleAuth } from '../GoogleAuth/GoogleAuth';
import getPlatform from '../../../utils/getPlatform';
import SEO from '../../../components/SEO/SEO';
import { TelegramAuth } from '../TelegramAuth/TelegramAuth';
import { Browser } from '@capacitor/browser';
import { REACT_APP_API_URL } from '../../../api/instance';
import { getAssesTokenStorage, getRefreshTokenStorage } from '../../../api';

type SignUpForm = {
  email: string;
  password: string;
};

type TAgreeKeys = keyof TAgree;

const SignUp: React.FC = () => {
  const { user } = useUserContext();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { saveTempUserData } = useUserContext();
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation(['translation']);
  const platform = getPlatform();

  // TODO: Make Guard/Middleware instead?
  useEffect(() => {
    if (user) {
      if (
        ![
          PlatformsEnum.IN_APP_BROWSER_ANDROID,
          PlatformsEnum.IN_APP_BROWSER_IOS,
        ].includes(platform)
      ) {
        navigate(MAIN_PAGE_ROUTE);
      } else {
        window.location.href = `${process.env.REACT_APP_API_AUTHURL}${MainRoutes.auth}${AuthRoutes['in-app-browser-mode-callback']}?accessToken=${getAssesTokenStorage()}&refreshToken=${getRefreshTokenStorage()}`;
      }
    }
  }, [navigate, user]);

  useEffect(() => {
    const platform = getPlatform();
    if ([PlatformsEnum.IOS, PlatformsEnum.ANDROID].includes(platform)) {
      const platformRoute =
        platform === PlatformsEnum.IOS
          ? AuthRoutes['in-app-browser-mode-ios']
          : AuthRoutes['in-app-browser-mode-android'];
      Browser.open({
        url: `${MainRoutes.auth}${platformRoute}?originPage=${encodeURIComponent(`${MainRoutes.auth}${AuthRoutes.signup}`)}`,
      });
    }
  }, []);

  const { values, onChange, errors, validate } = useForm<SignUpForm>({
    email: '',
    password: '',
  });

  const schema = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .email(ErrorMessages.email)
          .required(ErrorMessages.required),
        password: yup.string().required(ErrorMessages.required),
      }),
    []
  );

  const signUpHandler = async () => {
    setIsLoading(true);
    setError('');
    const hasErrors = await validate(schema);

    if (hasErrors) {
      setIsLoading(false);
      return;
    }

    signUp(values.email, values.password)
      .then((data) => {
        if (data) {
          saveTempUserData(data);
          let signupUrl = MainRoutes.auth + AuthRoutes.code;
          if (searchParams.has('redirect')) {
            signupUrl = `${signupUrl}?redirect=${searchParams.get('redirect')}`;
          }

          return navigate(signupUrl, {
            state: {
              ...values,
            },
          });
        }
      })
      .catch((error: AxiosError<any>) => {
        Object.keys(error.response?.data).forEach((key) => {
          if (error.response?.data?.[key]) {
            setError(error.response?.data?.[key]);
          }
        });
      })
      .finally(() => setIsLoading(false));
  };

  // TODO: It's bad practice to make links as buttons
  // SUGGESTION: Make option to support link on MButton
  const goToSignIn = () => navigate(MainRoutes.auth + AuthRoutes.signin);

  const downloadFile = async (key: TAgreeKeys) => {
    try {
      // Получите ссылку на файл из API
      const data = await getDocsAgree();
      if (data && data?.results?.[0]?.[key]) {
        // Создайте ссылку для скачивания
        const a = document.createElement('a');
        a.href = data?.results?.[0]?.[key];
        a.target = '_blank';

        // Установите имя файла, если это необходимо
        a.download = `${key}.pdf`; // Измените имя файла и его расширение по необходимости

        // Имитация клика по ссылке
        document.body.appendChild(a);
        a.click();

        // Удалите ссылку после скачивания
        document.body.removeChild(a);
      } else {
        console.error('Invalid data received from API');
      }
    } catch (error) {
      console.error('Error fetching file link:', error);
    }
  };

  return (
    <AuthLayout
      title={t('profile.signUp')}
      desc={t('auth.signupDesc')}
      bgImage="sign-up"
    >
      <SEO
        title={t('pages.sign-up.meta.title')}
        desc={t('pages.sign-up.meta.desc')}
      />
      <div className="flex flex-col gap-y-2">
        {[
          PlatformsEnum.WEB,
          PlatformsEnum.ANDROID,
          PlatformsEnum.IOS,
          PlatformsEnum.IN_APP_BROWSER_ANDROID,
          PlatformsEnum.IN_APP_BROWSER_IOS,
        ].includes(platform) && <GoogleAuth signIn={false} />}
        {[
          PlatformsEnum.WEB,
          PlatformsEnum.IOS,
          PlatformsEnum.IN_APP_BROWSER_IOS,
        ].includes(platform) && <AppleAuth signIn={false} />}
        {[
          PlatformsEnum.WEB,
          PlatformsEnum.TELEGRAM,
          PlatformsEnum.IN_APP_BROWSER_ANDROID,
          PlatformsEnum.IN_APP_BROWSER_IOS,
        ].includes(platform) && <TelegramAuth />}
      </div>
      <div className="my-4 flex items-center gap-3 md:my-8">
        <div className="h-px w-full bg-purple-100" />
        <p className="text-xs font-medium leading-4 text-purple-300 md:text-sm md:leading-[18px]">
          {t('profile.or')}
        </p>
        <div className="h-px w-full bg-purple-100" />
      </div>
      <div className="flex flex-col gap-4">
        <MInput
          label={t('profile.email')}
          placeholder="example@mail.com"
          id="email"
          name={'email'}
          type="text"
          bottomText={errors.email}
          state={errors.email ? 'error' : 'default'}
          value={values.email}
          onChange={onChange}
          inputClassName={'w-full leading-[18px]'}
          wrapperClassName={'w-full'}
          autoComplete="username"
        />
        <MInput
          label={t('profile.password')}
          placeholder="Min. 8 characters"
          id="password"
          type="password"
          name={'password'}
          bottomText={errors.password}
          state={errors.password ? 'error' : 'default'}
          value={values.password}
          onChange={onChange}
          inputClassName={'w-full leading-[18px]'}
          wrapperClassName={'w-full'}
          autoComplete="new-password"
        />
        <div className="flex items-center justify-between px-2">
          <div className="flex items-center">
            <p className="text-xs font-medium text-navy-700 md:text-sm">
              <Trans
                i18nKey="auth.signUpTerms"
                components={[
                  <span
                    className={
                      'cursor-pointer text-purple-500 hover:text-purple-600'
                    }
                    onClick={() => downloadFile('terms_and_conditions')}
                  />,
                  <span
                    className={
                      'cursor-pointer text-purple-500 hover:text-purple-600'
                    }
                    onClick={() => downloadFile('privacy_policy')}
                  />,
                ]}
              />
            </p>
          </div>
        </div>
        {!!error && (
          <p className={'text-center text-xs text-red-600 md:text-sm'}>{error}</p>
        )}
        <div className="flex flex-col gap-y-2">
          <MButton
            variant="highlighted"
            color="primary"
            onClick={signUpHandler}
            size={'md'}
            disabled={isLoading || !values.email || !values.password}
            loading={isLoading}
          >
            {t('profile.createAccount')}
          </MButton>
          <MButton
            variant="secondary"
            color="primary"
            onClick={goToSignIn}
            size={'md'}
            disabled={isLoading}
          >
            {t('profile.signIn')}
          </MButton>
        </div>
      </div>
    </AuthLayout>
  );
};

export default SignUp;
