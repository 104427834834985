import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AuctionViewBet } from './auction-view-bet';
import classNames from 'classnames';
import { useBidContext } from '../../../../providers/BidProvider/BidProvider';
import { useUserContext } from '../../../../providers';
import { placeBid } from '../../../../api/bid';
import getErrorMessages from '../../../../utils/getErrorMessages';
import { MButton } from '../../../../horizon-components/MButton';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Bid } from '../../../../models/Auction';
import { formatNumber } from '../../../../shared/utils/formatNumber';
dayjs.extend(relativeTime);
interface AuctionViewPlaceBidProps {
  className?: string;
  onShowBet?: (show: boolean) => void;
  onBid?: () => void;
}

export const AuctionViewPlaceBid = ({
  onShowBet,
  onBid,
}: AuctionViewPlaceBidProps) => {
  const { t, i18n } = useTranslation(['translation']);
  const { id } = useParams();
  const { user } = useUserContext();
  const {
    fetchMyBids,
    fetchAuction,
    bids,
    actualBid,
    getMyBidForAuction,
    fetchBids,
    auction,
  } = useBidContext();
  const { wallet, updateWallet } = useUserContext();
  const [isSending, setIsSending] = useState(false);
  const [bid, setBid] = useState<Bid | undefined>();

  const myBid = id ? getMyBidForAuction(parseInt(id)) : null;
  const er = wallet ? Math.floor(parseFloat(wallet.value)) : 0;

  const sendBid = () => {
    setIsSending(true);
    placeBid(Number(id || '0'), actualBid.toString())
      .then(() => {
        id && fetchAuction(id);
        id && fetchBids(id);
        fetchMyBids();
        updateWallet();
        onBid?.();
      })
      .catch((error) => {
        toast(getErrorMessages(error?.response?.data), {
          type: 'error',
        });
      })
      .finally(() => {
        setIsSending(false);
        onShowBet?.(false);
      });
  };

  useEffect(() => {
    setBid(bids?.results.find((item) => Number(item.bid_amount) === actualBid - 1));
  }, [bids?.results]);

  const sameBid = useMemo(
    () => bids?.results.findIndex((item) => Number(item.bid_amount) === actualBid),
    [actualBid, bids?.results]
  );

  // Новая ставка должна быть выше моей предыдущей ставки
  const myPreviousBidAmount =
    Number(bids?.results.find((bid) => bid.user.guid === user?.guid)?.bid_amount) ||
    0;

  // Ставка должна быть выше минимальной выигрышной ставки(от кого угодно)
  const lowestWinningBidAmount =
    Number(
      bids?.results?.find(
        (bid, idx) => auction?.number_winners && idx + 1 === auction?.number_winners
      )?.bid_amount
    ) || 0;

  const highestBidInAuction = Number(bids?.results?.[0]?.bid_amount) || 0;

  const summaryTokensAmount = er + (Number(myBid?.bid_amount) || 0);
  const cantAffordBid = summaryTokensAmount < actualBid;

  const step = auction?.step || 0;
  const isRestrictedByStep = step > 0;
  const maxBidRestrictedByStep = highestBidInAuction + step;
  const isBetBiggerThanStep = maxBidRestrictedByStep < actualBid;

  const maxAmountUserCanBid =
    summaryTokensAmount > maxBidRestrictedByStep && isRestrictedByStep
      ? maxBidRestrictedByStep
      : summaryTokensAmount;

  // 1. Больше нуля
  // 2. Больше предыдущей МОЕЙ ставки(если таковая имеется)
  // 3. Больше минимальной выигрышной ставки(от кого угодно)
  // 4. Нет ставки с той же суммой(от кого угодно)
  // 5. Могу позволить себе ставку(сумма предыдущей ставки + доп сумма <= балансу)
  // 6. Ставка не превышает шаг(чтобы не было чуваков которые сразу ставку слишком большую ставят)
  // или шага вовсе нет
  const canPlaceBet =
    actualBid > 0 &&
    myPreviousBidAmount < actualBid &&
    lowestWinningBidAmount < actualBid &&
    sameBid === -1 &&
    !cantAffordBid &&
    (!isRestrictedByStep || !isBetBiggerThanStep);

  return (
    <>
      <div className="!flex flex-col gap-5 lg:max-w-96 lg-max:px-2">
        <div className="flex flex-col gap-2">
          <h3 className="hidden text-lg font-bold leading-snug text-primary-primary lg-max:block">
            {t('bid.Place_a_bet')}
          </h3>
          {!!bid?.id && (
            <div
              className={
                'flex justify-between rounded-[12px] bg-secondaryGrey-200 p-[8px]'
              }
            >
              <div className={'flex items-center gap-[10px]'}>
                <img
                  src={
                    bid.user.user_meta.profile_image ||
                    require('../../../../assets/images/no-avatar.png')
                  }
                  className={
                    'h-[40px] w-[40px] min-w-[40px] rounded-full object-cover'
                  }
                  alt={'av'}
                />
                <div>
                  <p
                    className={
                      'w-40 truncate text-[16px] font-bold text-secondaryGrey-900'
                    }
                  >
                    {bid.user.user_meta.username}
                  </p>
                  <p className={'font-500 text-[14px] text-gray-500'}>
                    {dayjs(bid.created_at).fromNow()}
                  </p>
                </div>
              </div>
              <div className={'flex flex-col items-end'}>
                <p className={'text-[16px] font-[600] text-secondaryGrey-800'}>
                  {formatNumber(bid.bid_amount.toString(), i18n.language)}
                </p>
                <p className={'text-[10px] font-bold text-primary-purple-blue-500'}>
                  {user?.guid === bid.user.guid
                    ? t('bid.YOUR_BID').toUpperCase()
                    : t('bid.OUTBID_THE_BID').toUpperCase()}
                </p>
              </div>
            </div>
          )}
        </div>

        <AuctionViewBet />

        <div className="flex flex-col gap-5 lg-max:gap-2">
          <div
            className={classNames(
              'flex flex-col items-center gap-1.5 rounded-full bg-primary-red-100 p-2 text-center lg:px-5',
              {
                '!bg-green-100': canPlaceBet,
              }
            )}
          >
            {actualBid === 0 ? (
              <p className={'font-500 text-xs text-primary-red-500'}>
                {t('bid.The_bid_must_be_greater_than_0')}
              </p>
            ) : null}

            {actualBid > 0 && lowestWinningBidAmount > actualBid ? (
              <p className={'font-500 text-xs text-primary-red-500'}>
                {t('bid.The_bet_cannot_be_less_than_the_minimum_winning_one')}
              </p>
            ) : null}

            {sameBid !== -1 ? (
              <p className={'font-500 text-xs text-primary-red-500'}>
                {t('bid.This_bet_has_already_been_placed_by_another_participant')}
              </p>
            ) : null}

            {cantAffordBid && (
              <p className={'font-500 text-xs text-primary-red-500'}>
                {t('bid.Your_bet_exceeds_the_balance')}
              </p>
            )}

            {isRestrictedByStep && isBetBiggerThanStep && (
              <p className={'font-500 text-xs text-primary-red-500'}>
                {t('bid.Bet_exceeded_step')}
              </p>
            )}

            {canPlaceBet && (
              <p className={'font-500 text-xs text-green-500'}>
                {t('bid.You_can_place_a_bet')}
              </p>
            )}

            <p
              dangerouslySetInnerHTML={{
                __html: t('bid.You_can_bet_up_to', {
                  toEr: formatNumber(maxAmountUserCanBid.toString(), i18n.language),
                }),
              }}
              className={'font-500 text-sm text-secondaryGrey-900'}
            />
          </div>

          <MButton
            variant={'highlighted'}
            color={'primary'}
            size={'sm'}
            className={'h-[42px] w-full'}
            onClick={sendBid}
            disabled={!canPlaceBet}
          >
            {t('buttons.Confirm')}
          </MButton>
        </div>
      </div>
    </>
  );
};
