import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CoinIcon } from '../../../../components/CoinIcon';
import { MButton } from '../../../../horizon-components/MButton';
import { BidStatusEnum } from '../../../../models/Auction';
import { useUserContext } from '../../../../providers';
import { useBidContext } from '../../../../providers/BidProvider/BidProvider';
import { AuctionViewAbout } from './auction-view-about';
import { AuctionViewBids } from './auction-view-bids';
import { AuctionViewRate } from './auction-view-rate';
import { AuctionViewRules } from './auction-view-rules';
import { sendBid } from '../../../../hooks/useAnalytics';
import { formatNumber } from '../../../../shared/utils/formatNumber';

export const AuctionViewContent = () => {
  const { t, i18n } = useTranslation(['translation']);
  const { user } = useUserContext();
  const { auction, bids, setIsSendBidMode, setActualBid } = useBidContext();
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    const bidsRes = bids?.results.slice(0, auction?.number_winners) || [];
    const userBidPos = bidsRes.findIndex((bid) => {
      return bid.user.guid === user?.guid;
    });
    const win_pos =
      typeof userBidPos !== 'undefined' && userBidPos > -1
        ? userBidPos
        : (auction?.number_winners || 1) - 1;
    const winners = auction?.number_winners || 1;
    let recomBid = 1;
    if (bidsRes.length >= winners) {
      recomBid = Number(bidsRes[win_pos]?.bid_amount) + 1;
    }
    if (userBidPos === 0) {
      recomBid = Number(bidsRes[userBidPos]?.bid_amount) + 1;
    }
    const endPos = userBidPos === -1 ? bidsRes.length : userBidPos;
    const reBidsRes = bidsRes.reverse();
    for (let i = 0; i < endPos; i++) {
      if (recomBid === Number(reBidsRes[i]?.bid_amount)) {
        recomBid += 1;
      }
    }
    setActualBid(recomBid);
    setAmount(recomBid);
  }, [auction, bids?.results, setActualBid, user?.guid]);

  const handleBid = () => {
    setIsSendBidMode(true);
    sendBid();
    setActualBid(amount);
  };

  return (
    <div className={'flex w-full flex-col gap-3.5'}>
      <h1 className={'py-2 text-lg font-semibold text-gray-900 lg:text-2xl'}>
        {auction?.title}
      </h1>

      {!!auction && auction?.status === BidStatusEnum.COMPLETED && (
        <div className={'rounded-full bg-secondaryGrey-300 p-4'}>
          <p className={'text-center text-2xl font-semibold text-gray-800'}>
            {t('bid.finished')}
          </p>
        </div>
      )}

      {auction?.status === BidStatusEnum.ACTIVE && (
        <>
          <MButton
            variant={'highlighted'}
            color={'primary'}
            size={'sm'}
            className={
              'bottom-16 z-10 mb-[calc(env(safe-area-inset-bottom))] !h-12 lg-max:fixed lg-max:w-[calc(100vw_-_16px)]'
            }
            onClick={handleBid}
          >
            {t('bid.rate', {
              bid: formatNumber(amount.toString(), i18n.language),
            })}
            <CoinIcon />
          </MButton>
          <AuctionViewRate />
        </>
      )}

      {auction?.status !== 'planned' && <AuctionViewBids />}

      <AuctionViewAbout />
      <AuctionViewRules />
    </div>
  );
};
