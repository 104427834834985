import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  attachFileCfDirect,
  changeTopic,
  deleteTopic,
  deleteTopicAnswer,
  deleteTopicQuestion,
  detachContent,
} from '../../../../api/course';
import InputField from '../../../../horizon-components/Input/InputField';
import { MButton } from '../../../../horizon-components/MButton/ui/MButton';
import { TTopicQuestionItem, Topic, UnitItem } from '../../../../models/Course';
import getErrorMessages from '../../../../utils/getErrorMessages';
import { generateUUID } from '../../../../utils/uuid';
import { TopicContentUploader } from './TopicContentUploader';

import { useParams } from 'react-router-dom';
import { ReactComponent as FaChevronUp } from '../../../../assets/icons/chevron-up.svg';
import { ReactComponent as TrashSmall } from '../../../../assets/icons/trash.18px.svg';
import { SelectOptions } from '../../../../horizon-components/MMultSelect/MMultiSelect';
import { QuestionItem } from './QuestionItem';
import useScript from '../../../../hooks/useScript';

declare global {
  interface Window {
    Uppy: any;
    Tus: any;
  }
}

interface TopicItemProps {
  className?: string;
  topic: Topic;
  unit: UnitItem | null;
  updateCourseUnit: (v: UnitItem) => void;
  languages: SelectOptions[];
  addTextInTopic: (text: string, topicId: number) => void;
}

export const TopicItem = ({
  className,
  topic,
  unit,
  updateCourseUnit,
  languages,
  addTextInTopic,
}: TopicItemProps) => {
  const { id } = useParams();
  const { t } = useTranslation(['translation']);
  const [uploadingFile, setUploadingFile] = useState<number | null>(null);
  const [deletingContent, setDeletingContent] = useState<number | null>(null);
  const [titleChangeMode, setTitleChangeMode] = useState<number | null>(null);
  const [topicsText, setTopicsText] = useState<{ topicId: number; text: string }[]>(
    []
  );
  const [title, setTitle] = useState('');
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isUnitTextMode, setIsUnitTextMode] = useState(unit?.topics?.length === 1);

  useScript('/scripts/uppy/uppy.min.js');

  useEffect(() => {
    setIsUnitTextMode(unit?.topics?.length === 1);
  }, [unit?.topics?.length]);

  useEffect(() => {
    if (unit?.topics?.length && unit?.topics?.[0].id === topic.id) {
      handleRename(unit?.topics?.[0].title || unit.unit.title, topic.id);
    }
  }, [unit?.topics?.length]);

  const toggleCollapse = () => setIsCollapsed(!isCollapsed);

  const changeTitle = (value: string) => setTitle(value);

  const updateUnit = () => {
    unit &&
      updateCourseUnit({
        ...unit,
        topics: [...unit.topics],
      });
  };

  const removeContent = (topicId: number, contentId: number) => {
    setDeletingContent(topicId);
    detachContent(topicId, contentId)
      .then(() => {
        const contents = unit?.topics?.find((el) => el.id === topicId)?.contents;
        const index = contents?.findIndex((el) => el.id === contentId);
        if (typeof index === 'number' && index >= 0 && contents) {
          contents.splice(index, 1);
          updateCourseUnit({ ...unit });
        }
      })
      .catch((error) => {
        toast(getErrorMessages(error?.response?.data), {
          type: 'error',
        });
      })
      .finally(() => {
        setDeletingContent(null);
      });
  };

  const removeTopic = (topicId: number) => {
    deleteTopic(topicId)
      .then(() => {
        if (unit) {
          const index = unit.topics.findIndex((el) => el.id === topicId);
          if (index >= 0) {
            unit.topics.splice(index, 1);
            updateCourseUnit({
              ...unit,
            });
            setTopicsText((v) => [...v.filter((el) => el.topicId !== topicId)]);
            if (unit.topics.length === 1) {
              setIsUnitTextMode(true);
            }
          }
        }
      })
      .catch((error) => {
        toast(getErrorMessages(error?.response?.data), {
          type: 'error',
        });
      });
  };

  const attachFile = async (file: File[], topicId: number) => {
    setUploadingFile(topicId);

    try {
      const fileToAttach = file[0];
      let uid = '';

      const uppy = new window.Uppy.Uppy({
        restrictions: { maxNumberOfFiles: 1 },
        autoProceed: false,
      }).use(window.Uppy.Tus, {
        endpoint: `${process.env.REACT_APP_API_URL}/api/edu/attach_file_cf_direct/`,
        chunkSize: 150 * 1024 * 1024,
        allowedMetaFields: true,
        metadata: {
          name: `${fileToAttach.name}-course-${id}`,
        },
        onAfterResponse: (_: any, res: any) => {
          uid = res.getHeader('Stream-Media-Id');
        },
      });

      uppy.addFile({
        name: fileToAttach.name,
        type: fileToAttach.type,
        data: fileToAttach,
      });

      await uppy.upload();

      const attach = await attachFileCfDirect(topicId, uid);

      unit?.topics
        ?.find((el) => el.id === topicId)
        ?.contents.push({
          ...attach.content,
          file: attach.upload_url,
        });
      unit && updateCourseUnit({ ...unit });
    } catch (error: any) {
      if (!!error?.response?.data?.file?.length) {
        toast(error?.response?.data?.file[0], {
          type: 'error',
        });
      }
    } finally {
      setUploadingFile(null);
    }
  };

  const addTestTopic = () => {
    const question: TTopicQuestionItem = {
      id: -1,
      uuid: generateUUID(),
      text: '',
      topic_answers: [
        {
          text: '',
          correct: false,
        },
      ],
      is_multiple_answers: false,
    };
    topic.questions = [...(topic.questions || []), question];
    updateUnit();
  };

  const updateTopicQuestion = (question: TTopicQuestionItem, index: number) => {
    topic.questions?.splice(index, 1, question);
    updateUnit();
  };

  const removeQuestion = (question: TTopicQuestionItem, index: number) => {
    if (question.id > 0) {
      deleteTopicQuestion(question.id).then(() => {
        topic.questions?.splice(index, 1);
        topic.questions = [...(topic.questions || [])];
        updateUnit();
      });
    } else {
      topic.questions?.splice(index, 1);
      topic.questions = [...(topic.questions || [])];
      updateUnit();
    }
  };

  const handleSaveTitle = (topicId: number) => {
    setTitle('');
    setTitleChangeMode(null);
    changeTopic(topicId, title)
      .then(() => {
        if (unit) {
          const topic = unit.topics.find((el) => el.id === topicId);
          if (topic) {
            topic.title = title;
            updateCourseUnit({
              ...unit,
            });
          }
        }
      })
      .catch((error) => {
        toast(getErrorMessages(error?.response?.data), {
          type: 'error',
        });
      });
  };

  const handleRename = (topic: string, id: number) => {
    setTitle(topic);
    setTitleChangeMode(id);
  };

  return (
    <div
      className={classNames(
        'relative z-10 w-full rounded-[20px] bg-white p-[8px] pt-[20px] shadow-xl md:rounded-[30px] md:p-[24px] md:pt-[24px]',
        className
      )}
    >
      <div className={'flex items-center justify-between gap-4'}>
        {unit?.topics?.length && unit?.topics?.length > 1 ? (
          <>
            {titleChangeMode === topic.id ? (
              <InputField
                value={title}
                onChange={(e) => changeTitle(e.target.value)}
                extra={'w-full'}
                placeholder={t('course.enter_topic_name')}
                onBlur={() => handleSaveTitle(topic.id)}
                onKeyUp={(e) => e.key === 'Enter' && handleSaveTitle(topic.id)}
                autoFocus
              />
            ) : (
              <p
                className={'g-small-title'}
                onClick={() => handleRename(topic.title, topic.id)}
              >
                {topic.title}
              </p>
            )}
          </>
        ) : (
          <div></div>
        )}
        <div className={'flex items-start gap-x-2'}>
          {unit?.topics?.[0].id !== topic.id && (
            <MButton
              size={'xs'}
              variant={'outline'}
              color={'primary'}
              onClick={() => removeTopic(topic.id)}
            >
              <TrashSmall />
              {t('buttons.Delete')}
            </MButton>
          )}
          <MButton
            variant={'secondary'}
            color={'primary'}
            size={'xs'}
            onClick={toggleCollapse}
            className={'!h-[42px] !w-[42px] !p-3.5'}
          >
            {isCollapsed ? (
              <FaChevronUp className="h-[14px] w-[14px]" />
            ) : (
              <FaChevronUp
                style={{ transform: 'rotate(180deg)' }}
                className="h-[14px] w-[14px]"
              />
            )}
          </MButton>
        </div>
      </div>

      <div className={classNames({ block: !!isCollapsed, hidden: !isCollapsed })}>
        <TopicContentUploader
          content={topic?.contents || []}
          uploading={uploadingFile === topic.id}
          deletingContent={deletingContent === topic.id}
          onChangeText={(text) => addTextInTopic(text, topic.id)}
          onChangeFile={(f) => attachFile(f, topic.id)}
          removeContent={(contentId) => removeContent(topic.id, contentId)}
          isUnitText={isUnitTextMode}
          topicId={topic.id}
          key={topic.id}
        />
        <div className={'mt-3'}>
          {!!topic?.questions?.length && (
            <div
              className={
                'mb-3 mt-3 rounded-[16px] border-[1px] border-purple-200 p-[16px]'
              }
            >
              <p className={'mb-2 font-medium'}>{t('edu.Topic_test')}</p>
              {topic?.questions?.map((question, i) => (
                <QuestionItem
                  updateQuestion={(data) =>
                    updateTopicQuestion(data as TTopicQuestionItem, i)
                  }
                  question={question}
                  index={i + 1}
                  isTopicAnswer
                  removeAnswerFromBack={deleteTopicAnswer}
                  removeQuestion={() => removeQuestion(question, i)}
                  key={question?.uuid || question.id}
                />
              ))}
            </div>
          )}
          {!isUnitTextMode && (
            <div className={'flex items-center justify-between'}>
              <div className={'h-[2px] w-full bg-purple-100'} />
              <MButton
                onClick={addTestTopic}
                color={'primary'}
                variant={'secondary'}
                className={'min-w-fit'}
                size={'xs'}
              >
                {!!topic?.questions?.length
                  ? t('edu.Question') + ' +'
                  : t('course.addTopicTestQuestion')}
              </MButton>
              <div className={'h-0.5 w-full bg-purple-100'} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
