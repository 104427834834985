import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import useFetch from '../../hooks/useFetch';
import {
  Auction,
  Bid,
  MyBidsResponse,
  MyWonAuctionsResponse,
} from '../../models/Auction';
import { getAuctionById, getBids, getMyBids, getWonAuctions } from '../../api/bid';
import { useUserContext } from '../UserProvider';
import { ResponsePagination } from '../../models/Response';

type BidProvider = {
  auction: Auction | null;
  myBids: MyBidsResponse['my_bids'];
  bids: ResponsePagination<Bid> | null;
  fetchMyBids: () => Promise<MyBidsResponse | undefined>;
  getMyBidForAuction: (auction_id: number) => Bid | null;
  wonAuctionsData: MyWonAuctionsResponse['won_auctions'];
  fetchMyWonAuctions: () => Promise<MyBidsResponse | undefined>;
  getWonAuctionInfo: (auction_id: number) => {
    isRewardClaimed: boolean;
    isAuctionWon: boolean;
  };
  fetchAuction: (auction_id: string) => void;
  fetchBids: (
    auction_id: string,
    page?: number
  ) => Promise<ResponsePagination<Bid> | undefined>;
  setActualBid: (bid: number) => void;
  setIsSendBidMode: (isSend: boolean) => void;
  isSendBidMode: boolean;
  actualBid: number;
  isLoading: boolean;
  isBidsLoading: boolean;
};

const BidContext = createContext<BidProvider>({} as BidProvider);

const BidProvider: React.FC<PropsWithChildren> = (props) => {
  const { children } = props;
  const { user } = useUserContext();
  const [actualBid, setActualBid] = useState(0);
  const [isSendBidMode, setIsSendBidMode] = useState(false);
  const { data: bidsData, fetchData: fetchMyBids } = useFetch(getMyBids);
  const {
    data: bids,
    isLoading: isBidsLoading,
    fetchData: fetchBids,
  } = useFetch(getBids);
  const {
    data: auction,
    isLoading,
    fetchData: fetchAuction,
  } = useFetch(getAuctionById);
  const { data: wonAuctionsData, fetchData: fetchMyWonAuctions } =
    useFetch(getWonAuctions);

  const fetchMyBidsSafely = async () => {
    if (!user?.guid) {
      return undefined;
    }
    await fetchMyBids();
  };

  const fetchMyWonAuctionsSafely = async () => {
    if (!user?.guid) {
      return undefined;
    }
    await fetchMyWonAuctions();
  };

  useEffect(() => {
    fetchMyBidsSafely();
    fetchMyWonAuctionsSafely();
  }, [user?.guid]);

  const getMyBidForAuction = (auction_id: number) => {
    return bidsData?.my_bids[auction_id] || null;
  };

  const getWonAuctionInfo = (auction_id: number) => {
    if (!wonAuctionsData) {
      return {
        isRewardClaimed: false,
        isAuctionWon: false,
      };
    }

    const auctionWonData = wonAuctionsData.won_auctions[auction_id.toString()];

    return {
      isRewardClaimed: auctionWonData,
      isAuctionWon:
        wonAuctionsData.won_auctions[auction_id.toString()] !== undefined,
    };
  };

  return (
    <BidContext.Provider
      value={{
        bids: bids,
        auction: auction,
        myBids: bidsData?.my_bids || {},
        fetchMyBids: fetchMyBidsSafely,
        getMyBidForAuction,
        wonAuctionsData: wonAuctionsData?.won_auctions || {},
        fetchMyWonAuctions: fetchMyWonAuctionsSafely,
        getWonAuctionInfo,
        fetchAuction,
        fetchBids,
        setActualBid,
        setIsSendBidMode,
        isSendBidMode,
        actualBid,
        isLoading,
        isBidsLoading,
      }}
    >
      {children}
    </BidContext.Provider>
  );
};

const useBidContext = () => useContext(BidContext);

export { useBidContext, BidProvider };
