import { useEffect, useState } from 'react';
import { useUserContext } from '../providers';
import { getTokenomicConfigs } from '../api/auth';
import { getUserSomeDataStorage, setUserSomeDataStorage } from '../api';
import { parseDate } from '../shared/utils/parseDate';

export const useDailyRewardModal = () => {
  const [isStartOpen, setIsStartOpen] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const { user, setHasClaimedReward } = useUserContext();
  const today = new Date().toLocaleDateString();

  useEffect(() => {
    const checkDataLoaded = async () => {
      try {
        await getTokenomicConfigs();
        const lastClose = getUserSomeDataStorage('lastCloseRewardModal', user?.guid);
        const lastClaim = getUserSomeDataStorage('lastRewardClaim', user?.guid);
        const date1 = parseDate(today);
        const date2 = lastClaim && parseDate(lastClaim);
        const date3 = lastClose && parseDate(lastClose);

        if (!date3 || date1.getTime() !== date3.getTime()) {
          setIsDataLoaded(true);
        }

        if (date2 && date1.getTime() === date2.getTime()) {
          setHasClaimedReward(true);
        }
      } catch (error) {
        console.error('Error loading data:', error);
      }
    };

    checkDataLoaded();
  }, [today, user?.guid]);

  useEffect(() => {
    if (user?.guid && isDataLoaded) {
      const lastVisit = getUserSomeDataStorage('lastRewardClaim', user.guid);
      const date1 = parseDate(today);
      const date2 = lastVisit && parseDate(lastVisit);

      if (!date2 || date1.getTime() !== date2.getTime()) {
        hndlOpenStartModal();
      }
    }
  }, [user, isDataLoaded, today]);

  const forceSetDataLoaded = () => {
    setIsDataLoaded(true);
  };

  const hndlOpenStartModal = () => {
    setIsStartOpen(!isStartOpen);
  };

  const hndlCloseStartModal = () => {
    if (isStartOpen) {
      setIsStartOpen(false);
      setIsDataLoaded(false);
      setUserSomeDataStorage('lastCloseRewardModal', today, user?.guid);
    }
  };

  return {
    isStartOpen,
    hndlOpenStartModal,
    hndlCloseStartModal,
    forceSetDataLoaded,
  };
};
