import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import { getNotificationsConnect } from '../api/socket/socket';
import { TNotifications } from '../models/Notifications';
import { useUserContext } from './UserProvider';

type NotificationWebsocketContextType = {
  lastReceivedNotification: TNotifications | null;
};

const NotificationWebsocketContext = createContext<NotificationWebsocketContextType>(
  {} as NotificationWebsocketContextType
);

const NotificationsWebsocketProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { user, updateWallet } = useUserContext();
  const [connection, setConnection] = useState<WebSocket | null>(null);
  const [lastReceivedNotification, setLastReceivedNotification] =
    useState<null | TNotifications>(null);

  const startConnection = async () => {
    const websocketConnection = await getNotificationsConnect();
    websocketConnection.onmessage = (message: MessageEvent<string>) => {
      updateWallet();
      const notificationParsed = JSON.parse(message.data) as TNotifications;
      setLastReceivedNotification(notificationParsed);
    };
    setConnection(websocketConnection);
  };

  useEffect(() => {
    if (user?.guid) {
      startConnection();
    }

    return () => {
      connection?.close();
    };
  }, [user?.guid]);

  return (
    <NotificationWebsocketContext.Provider
      value={{
        lastReceivedNotification,
      }}
    >
      {children}
    </NotificationWebsocketContext.Provider>
  );
};

const useNotificationsWebsocket = () => useContext(NotificationWebsocketContext);

export {
  NotificationWebsocketContext,
  NotificationsWebsocketProvider,
  useNotificationsWebsocket,
};
