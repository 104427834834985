import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { saveAssesTokenStorage, saveRefreshTokenStorage } from '../../../api';
import { sendLogin, setUserID } from '../../../hooks/useAnalytics';
import { authMe } from '../../../api/auth';
import { MAIN_PAGE_ROUTE } from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { useNavigate } from '../../../hooks/useNavigate';
import { useUserContext } from '../../../providers';
import { getRedirectPath } from '../../../api/sessions';

export const InAppBrowserModeCallback = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { saveUser } = useUserContext();
  const redirectPath = getRedirectPath();

  useEffect(() => {
    const accessToken = searchParams.get('accessToken');
    const refreshToken = searchParams.get('refreshToken');

    if (accessToken && refreshToken) {
      saveAssesTokenStorage(accessToken);
      saveRefreshTokenStorage(refreshToken);
      sendLogin('Email');
      authMe().then((data) => {
        saveUser(data);
        setUserID(data.guid);
        const redirectUrl = searchParams.get('redirect');
        if (redirectUrl) {
          window.location.href = redirectUrl;
        } else {
          navigate(redirectPath || MAIN_PAGE_ROUTE);
        }
      });
    }
  }, []);

  return null;
};
