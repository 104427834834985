import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserContext } from '../../../../providers';
import { useBidContext } from '../../../../providers/BidProvider/BidProvider';
import { BidCard } from '../../../../widgets/bid-card';

export const AuctionViewBidsWinnerList = () => {
  const { t } = useTranslation(['translation']);
  const { user } = useUserContext();
  const { auction, bids } = useBidContext();
  const winnersLanght = (bids?.results?.filter((bid) => bid.is_winner) || [])
    ?.length;
  const number_winners =
    auction?.number_winners && winnersLanght < auction?.number_winners
      ? winnersLanght
      : auction?.number_winners || 0;

  const winners = useMemo(
    () =>
      bids?.results?.filter(
        (item, idx) =>
          item.is_winner &&
          (idx === 0 || idx === (number_winners ? number_winners - 1 : 0))
      ) || [],
    [number_winners, bids?.results]
  );

  if (!winners.length) return null;

  return (
    <>
      <div className={'flex w-full items-center justify-between'}>
        <p className={'text-[16px] font-[600] text-gray-900'}>
          {t('bid.Winning_bets')}
        </p>
        <div
          className={
            'rounded-full bg-purple-100 px-[8px] py-[2px] text-[12px] font-[600] text-purple-400'
          }
        >
          {bids?.count}
        </div>
      </div>
      {winners?.map((bid, idx) => (
        <BidCard
          bid={bid}
          place={idx + 1}
          isMy={bid.user.guid === user?.guid}
          isWin={bid.is_winner}
          isMin={idx + 1 === 2}
        />
      ))}
    </>
  );
};
