import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from '../../../../components/Loader';
import { Bid, BidStatusEnum } from '../../../../models/Auction';
import { useUserContext } from '../../../../providers';
import { useBidContext } from '../../../../providers/BidProvider/BidProvider';
import { BidCard } from '../../../../widgets/bid-card';
import { DEFAULT_LIMIT } from '../../../../shared/lib/const/limit';
import useFetch from '../../../../hooks/useFetch';
import { getBids } from '../../../../api/bid';
import classNames from 'classnames';
import { AuctionViewRate } from './auction-view-rate';

export const AuctionViewBidsAll = ({
  handleBid,
  isScroll = false,
}: {
  handleBid: (bid: number) => void;
  isScroll?: boolean;
}) => {
  const { id } = useParams();
  const { user } = useUserContext();
  const { auction, fetchAuction } = useBidContext();

  const { data, isLoading: isBidsLoading, fetchData: fetchBids } = useFetch(getBids);

  const [page, setPage] = useState(1);
  const [bids, setBids] = useState<Bid[]>([]);
  const targetRef = useRef<HTMLDivElement>(null);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [minBid, setMinBid] = useState<number>(0);
  const winnersLanght = (bids?.filter((bid) => bid.is_winner) || [])?.length;
  const number_winners =
    auction?.number_winners &&
    winnersLanght < auction?.number_winners &&
    auction?.status === BidStatusEnum.COMPLETED
      ? winnersLanght
      : auction?.number_winners || 0;

  useEffect(() => {
    setMinBid(
      number_winners
        ? Number(bids.find((_, idx) => idx + 1 === number_winners)?.bid_amount)
        : 0
    );
  }, [auction, bids, number_winners]);

  const loadMoreBids = useCallback(() => {
    if (id && !isLoadingMore && bids.length < (data?.count || 0)) {
      setIsLoadingMore(true);
      fetchBids(id, page + 1).then((res) => {
        setBids((prevBids) => [...prevBids, ...(res?.results || [])]);
        if (res?.results.length === DEFAULT_LIMIT) {
          setPage((prevPage) => prevPage + 1);
        }
        setIsLoadingMore(false);
      });
    }
  }, [id, page, data?.count, bids.length, fetchBids, isLoadingMore]);

  const handleScroll = useCallback(() => {
    const element = targetRef.current;
    if (
      element &&
      element.scrollTop + element.clientHeight >= element.scrollHeight - 10
    ) {
      loadMoreBids();
    }
  }, [loadMoreBids]);

  useEffect(() => {
    const element = targetRef.current;
    if (element) {
      element.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (element) {
        element.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  const firstFetchBids = useCallback(() => {
    setBids([]);
    if (id) {
      fetchBids(id, 1).then((res) => {
        setBids(res?.results || []);
        setIsFirstLoad(false);
      });
    }
  }, [fetchBids, id]);

  useEffect(() => {
    if (id && isFirstLoad) {
      firstFetchBids();
    }
  }, [id, isFirstLoad, firstFetchBids]);

  const userBid = bids?.findIndex((bid) => bid.user.guid === user?.guid);

  useEffect(() => {
    if (isScroll) {
      const element = document.getElementById('my-bid');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });

        setTimeout(() => {
          window.scrollBy({
            top: 0,
            behavior: 'smooth',
          });
        }, 300);
      }
    }
  }, [userBid, isScroll]);

  useEffect(() => {
    if (id && !auction?.id) {
      fetchAuction(id);
    }
  }, [id, auction?.id, fetchAuction]);

  return (
    <>
      <div
        ref={targetRef}
        className={classNames(
          'flex max-h-[50vh] min-h-[30vh] flex-col gap-3 overflow-y-auto pb-1 pr-4 ',
          {
            'max-h-[70vh]': isScroll,
            'max-h-[40vh]': !isScroll,
          }
        )}
      >
        {bids?.map((bid, idx) => (
          <>
            <BidCard
              bid={bid}
              key={bid.id}
              place={idx < number_winners ? idx + 1 : undefined}
              isMy={bid.user.guid === user?.guid}
              onBid={() => handleBid(Number(bid.bid_amount))}
              canBid={
                auction?.status === BidStatusEnum.ACTIVE
                  ? typeof userBid !== 'undefined' && userBid > -1
                    ? userBid === idx
                      ? minBid >= Number(bid.bid_amount) && userBid !== 0
                      : number_winners > idx && userBid > idx
                    : number_winners > idx
                  : false
              }
              isWin={
                bid.user.guid === user?.guid ||
                auction?.status === BidStatusEnum.COMPLETED
                  ? bid.is_winner
                  : idx < number_winners
              }
              isMin={idx + 1 === number_winners}
            />
            {idx + 1 === number_winners && <hr className="mt-1" />}
          </>
        ))}
        {isBidsLoading && <Loader />}
      </div>
      <AuctionViewRate onBid={firstFetchBids} />
    </>
  );
};
