import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Auction, BidStatusEnum } from '../../models/Auction';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import utc from 'dayjs/plugin/utc';
import duration, { Duration } from 'dayjs/plugin/duration';

dayjs.extend(utc);
dayjs.extend(dayOfYear);
dayjs.extend(relativeTime);
dayjs.extend(duration);

interface BidTimeProps {
  data: Auction;
  hideText?: boolean;
  onFetch?: () => void;
}

export const BidTime = ({ data, onFetch, hideText = false }: BidTimeProps) => {
  const { t } = useTranslation(['translation']);
  const [status, setStatus] = useState<BidStatusEnum>(BidStatusEnum.ACTIVE);
  const [time, setTime] = useState<Duration>(
    status === BidStatusEnum.PLANNED
      ? dayjs.duration(dayjs(data?.start_datetime).diff(dayjs()))
      : dayjs.duration(dayjs(data?.end_datetime).diff(dayjs()))
  );

  const formatTimer = (time: Duration) => {
    let resString = '';
    if (time.asDays() >= 1) {
      resString = resString + `${Math.trunc(time.asDays())} days `;
    }
    resString =
      resString +
      `${('0' + time.hours()).slice(-2)}:${('0' + time.minutes()).slice(-2)}:${('0' + time.seconds()).slice(-2)}`;
    return resString;
  };

  useEffect(() => {
    if (formatTimer(time) === '00:00:00') {
      onFetch?.();
    }
  }, [onFetch, time]);

  useEffect(() => {
    if (status === BidStatusEnum.COMPLETED) return;
    const id = setInterval(() => {
      setTime(
        status === BidStatusEnum.PLANNED
          ? dayjs.duration(dayjs(data?.start_datetime).diff(dayjs()))
          : dayjs.duration(dayjs(data?.end_datetime).diff(dayjs()))
      );
    }, 1000);
    return () => clearInterval(id);
  }, [data?.end_datetime, data?.start_datetime, status]);

  useEffect(() => {
    setStatus(data.status);
  }, [data.status]);

  return status === BidStatusEnum.ACTIVE ? (
    <>
      {!hideText ? t('bid.finishIn') : ''}{' '}
      <p className="tabular-nums">{formatTimer(time)}</p>
    </>
  ) : status === BidStatusEnum.PLANNED ? (
    <>
      {!hideText ? t('bid.startIn') : ''}{' '}
      <p className="tabular-nums">{formatTimer(time)}</p>
    </>
  ) : (
    <>
      {!hideText ? t('bid.Ended') : ''}{' '}
      {dayjs(data.end_datetime).local().format('DD MMM YYYY HH:mm')}
    </>
  );
};
